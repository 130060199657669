"use strict";

$('[data-toggle="tooltip"]').tooltip();

function isUrlProtocol(url) {
    var r = new RegExp("^(?:[a-z]+:)?//", "i");
    return r.test(url);
}

$(".js-link-protocol-fallback").click(function() {
    var url = $(this).attr("href");

    if (!isUrlProtocol(url)) {
        $(this).attr("href", "http://" + url);
    }
});
$(".js-set-back-url").click(function() {
    Cookies.set("backUrlExtranet", window.location.href);
});

function showInputError(input, error) {
    input.labels[0].innerHTML +=
        '<span class="small d-block text-danger">' + error + "</span>";
}

function clearInputError(input) {
    input.labels[0].children;
}

var resetButton = document.getElementById("form-reset");

if (resetButton !== null) {
    resetButton.addEventListener("click", function() {
        Cookies.remove(resetButton.dataset.form);
        window.location.href = window.location.href.split("?")[0];
    });
}

$(".datetimepicker").datetimepicker({
    locale: "de",
    minDate: new Date(),
    //debug: true,
    sideBySide: true
});
var flash = document.getElementById("flash-container");

function flashMessage(message, level) {
    if (level === void 0) {
        level = "danger";
    }

    flash.innerHTML +=
        '<div class="alert main-alert alert-' +
        level +
        '" role="alert">' +
        message +
        "</div>";
}

function removeClassByPrefix(el, prefix) {
    var regx = new RegExp("\\b" + prefix + ".*?\\b", "g");
    el.className = el.className.replace(regx, "");
    return el;
}

window.Parsley.setLocale("de");
var $parsleyForm = $(".parsley-form");

if ($parsleyForm.length) {
    $parsleyForm
        .parsley()
        .on("field:validated", function() {
            var ok = $(".parsley-error").length === 0;
        })
        .on("field:error", function() {
            var $section = $(this.$element)
                .parent()
                .parent()
                .parent()
                .parent();
            var $tab = $("#" + $section.attr("aria-labelledby"));
            $tab.addClass("parsley-error");
            $section.addClass("parsley-error");
        });
}