import $ from "jquery"
import moment from "moment"
import * as grapesjs from 'grapesjs';
import "bootstrap"
import "bootstrap-datepicker"
import "bootstrap-select"
import "lodash"
import "quill"
import "filesize"
import "eonasdan-bootstrap-datetimepicker"
import "js-cookie"
import "parsleyjs"
import "parsleyjs/dist/i18n/de"

// Styles
import "./styles/styles.scss"
import "./scripts/scripts";